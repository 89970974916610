import React, { useState, useEffect } from "react";
import styles from "./heroSlideshow.module.css";
import LOGO from "../images/injuro/LOGO.png";

const HeroSlideshow = ({
  images = [],
  interval = 3000, // 3 seconds by default
  title = "My Hero Title",
  subtitle = "Some descriptive text here..."
}) => {

  // Current index for slideshow
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    // If no images, bail out
    if (!images || images.length === 0) return;

    // Auto-advance the slideshow every 'interval' ms
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => 
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, interval);

    // Cleanup on unmount
    return () => clearInterval(timer);
  }, [images, interval]);

  return (
    <div className={`${styles.heroSlideshowContainer}`}>
      {/* Slideshow side */}
      <div className={styles.slideshowSide}>
      {images.map((img, idx) => (
        <div
            key={idx}
            className={styles.slideWrapper}
            style={{ opacity: idx === currentIndex ? 1 : 0 }}
        >
            <img
            src={img}
            alt={`Slide ${idx}`}
            className={styles.slideImage}
            />
            <div className={styles.gradientOverlay} />
        </div>
        ))}
      </div>

      {/* Text side */}
      <div className={styles.textSide}>
        <div className={styles.verticalText}> 
          <div class={styles.verticalNames}>
                <span>A</span>
                <span>R</span>
                <span>C</span>
                <span>H</span>
                <span>I</span>
                <span>T</span>
                <span>E</span>
                <span>C</span>
                <span>T</span>
                <span>U</span>
                <span>R</span>
                <span>E</span>
            </div>
            <div class={styles.verticalNames}>
                <span>I</span>
                <span>N</span>
                <span>T</span>
                <span>E</span>
                <span>R</span>
                <span>I</span>
                <span>O</span>
                <span>R</span>
            </div>
            <div class={styles.verticalNames}>
                <span>D</span>
                <span>E</span>
                <span>S</span>
                <span>I</span>
                <span>G</span>
                <span>N</span>
            </div>
            <div class={styles.verticalNames}>
                <span>I</span>
                <span>M</span>
                <span>A</span>
                <span>G</span>
                <span>E</span>
            </div>
            <div class={styles.textSideLogo}>
            </div>
        </div>
        <div className={styles.verticalLogo}> 
        <img
            src={LOGO}
            alt="Injuro Logo"
            />
          </div>
      </div>
    </div>
  );
};

export default HeroSlideshow;
