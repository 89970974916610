import React, { useRef, useState } from "react";
import { motion, AnimatePresence } from 'framer-motion'
import { useInView } from "react-cool-inview";
import Card from '../components/Card'
import FeaturedCard from './FeaturedCard'
import SplitFeaturedCard from "./SplitFeaturedCard";
import HeroSlideshow from "./HeroSlideshow";


import _1 from "../images/injuro/1.jpg"
import _2 from "../images/injuro/2.jpg"
import _3 from "../images/injuro/3.jpg"
import _4 from "../images/injuro/4.jpg"
import _5 from "../images/injuro/5.jpg"
import _6 from "../images/injuro/6.jpg"
import _7 from "../images/injuro/7.jpg"
import _8 from "../images/injuro/8.jpg"
import _9 from "../images/injuro/9.jpg"
import _10 from "../images/injuro/10.jpg"
import _11 from "../images/injuro/11.jpg"
import _12 from "../images/injuro/12.jpg"
import _13 from "../images/injuro/13.jpg"
import _14 from "../images/injuro/14.jpg"
import _15 from "../images/injuro/15.jpg"
import _16 from "../images/injuro/16.jpg"
import _17 from "../images/injuro/17.jpg"
import _18 from "../images/injuro/18.jpg"
import _19 from "../images/injuro/19.jpg"
import _20 from "../images/injuro/20.jpg"
import _21 from "../images/injuro/21.jpg"


function WorkGrid(props) {
  // const [visibleState, setVisibleState] = useState("initial");

  // Scroll Reveal
  // https://github.com/wellyshen/react-cool-inview
  // const { ref, inView, scrollDirection, entry, observe, unobserve } = useInView({
  //     threshold: 0.0, // Default is 0
  //     unobserveOnEnter: true,
  //     onEnter: ({ scrollDirection, entry, observe, unobserve }) => {
  //       setVisibleState("visible");
  //     },
  //     onLeave: ({ scrollDirection, entry, observe, unobserve }) => {
  //       setVisibleState("initial");
  //     }
  //   }
  // );

  const gridVariants = {
    initial: {
      opacity: 0.25,
      scale: 0.98,
      y: 60,
      transition: {
        type: "spring",
        duration: 0.8
      }
    },
    visible: {
      opacity: 1,
      scale: 1,
      y: 0,
      transition: {
        type: "spring",
        duration: 0.8
      }
    }
  };

  function projectEnter(event) {
    props.onProjectEnter();
  }

  function projectLeave(event) {
    props.onProjectLeave();
  }

  // function SomePage() {
  //   const images = [zero5, trecento, BAGNO_OPT_1];
  // }


  // TODO: list all the projects here

  return (
    <div className="grid">
      <div className="work-grid">
      {/* <FeaturedCard
            image={_1}
            imageAlt="Project 1"
            // link="/project1" # removing link to project
            color="white"
            title="BEATRICE D'ESTE"
            key="Project1"
            size="featured"
            projectType="RESIDENZIALE"
            projectYear="2023"
            projectSize="L"
            onProjectEnter={projectEnter}
            onProjectLeave={projectLeave}
          /> */}
      <HeroSlideshow
        images={[_1 ,_2 ,_3 ,_4 ,_5 ,_6 ,_7 ,_8 ,_9 ,_10,_11,_12,_13,_14,_15,_16,_17,_18,_19,_20,_21]}
        interval={4000}
        title="INJURO"
        subtitle="STUDIO"
      />
      </div>
    </div>
  )
}

export default WorkGrid