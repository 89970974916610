import * as React from "react"
import { useState } from "react"
import { motion } from "framer-motion"
import { Helmet } from "react-helmet"

import ClientOnly from "../components/ClientOnly.js"
import Cursor from "../components/Cursor.js"
import Navigation from "../components/Navigation.js"
import Hero from "../components/Hero.js"
import Footer from "../components/Footer.js"
import Card from "../components/Card.js"
import styles from '../components/AboutGrid.module.css'

import "../components/reset.css"
import "../components/global.css"

// markup
const AboutPage = () => {
  const [cursorText, setCursorText] = useState("");
  const [cursorVariant, setCursorVariant] = useState("default");

  function projectEnter(event) {
    setCursorText("View");
    setCursorVariant("project");
  }

  function cursorLeave(event) {
    setCursorText("");
    setCursorVariant("default");
  }

  function contactEnter(event) {
    setCursorText("👋");
    setCursorVariant("contact");
  }

  function photoEnter(event) {
    setCursorText("❤️");
    setCursorVariant("contact");
  }

  function linkEnter(event) {
    setCursorText("");
    setCursorVariant("link");
  }

  return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Injuro Interior Design - About Us</title>
          <meta name="description" content="Injuro is a design studio based in Milan." />
          <link rel="canonical" href="https://injuro.xyz/about/" />
        </Helmet>
        <Navigation onLinkEnter={linkEnter} onLinkLeave={cursorLeave} />
        <Hero text="Injuro is a design studio based in Milan" details={false} />
  
        <ClientOnly>
          <Cursor cursorText={cursorText} cursorVariant={cursorVariant} />
        </ClientOnly>

        <motion.section className="grid">
          <div className={styles.aboutGrid}>
            <motion.div className={`${styles.video} ${styles.first}`}>
              <video src="https://placehold.co/1080x1920.mp4" className={styles.video} autoPlay muted loop />
            </motion.div>
            <motion.div className={styles.wrapper}>
              <div className={styles.smallVideo}>
                <video src="https://placehold.co/1080x1100.mp4" className={styles.video} autoPlay muted loop />
              </div>
              <div className={`${styles.smallVideo} ${styles.second}`}>
                <video src="https://placehold.co/1080x1200.mp4" className={styles.video} autoPlay muted loop onMouseEnter={photoEnter} onMouseLeave={cursorLeave}/>
              </div>
            </motion.div>
            <motion.div className={`${styles.video} ${styles.third}`}>
              <video src="https://placehold.co/1080x1920.mp4" className={styles.video} autoPlay muted loop  onMouseEnter={photoEnter} onMouseLeave={cursorLeave}/>
            </motion.div>
            <motion.div className={`${styles.video} ${styles.fourth}`}>
              <video src="https://placehold.co/1080x1920.mp4" className={styles.video} autoPlay muted loop onMouseEnter={photoEnter} onMouseLeave={cursorLeave} />
            </motion.div>
          </div>
        </motion.section>
        <motion.section className="grid about">
          <div className="bio">
            <h2 className="h2">Blabla bla </h2>
          </div>
          <div className="bio-body">
            <p className="paragraph paragraph--large">
            Blabla bla Blabla blaBlabla blaBlabla blaBlabla blaBlabla blaBlabla blaBlabla blaBlabla bla,
            Blabla blaBlabla blaBlabla blaBlabla blaBlabla bla
            </p>
            <p className="paragraph paragraph--large">
            Blabla blaBlabla blaBlabla blaBlabla blaBlabla blaBlabla blaBlabla blaBlabla bla
            Blabla blaBlabla blaBlabla blaBlabla blaBlabla blaBlabla blaBlabla bla.
            </p>
            <p className="paragraph paragraph--large">
            Blabla blaBlabla blaBlabla blaBlabla blaBlabla blaBlabla blaBlabla bla.
            </p>
          </div>
        </motion.section>

        <motion.section className="grid about">
          <div className="experience">
            <h2 className="h2">Experience</h2>
            <ul className="list">
              <li className="list-item">
                <h6 className="h6 timeline">
                  2020 - Current
                </h6>
                <h4 className="h4">
                Blabla blaBlabla blaBlabla bla
                </h4>
              </li>
              <li className="list-item">
                <h6 className="h6 timeline">
                  2018 - 2020
                </h6>
                <h4 className="h4">
                Blabla blaBlabla blaBlabla bla
                </h4>
              </li>
              <li className="list-item">
                <h6 className="h6 timeline">
                  2015 - 2018
                </h6>
                <h4 className="h4">
                Blabla blaBlabla bla
                </h4>
              </li>
              <li className="list-item">
                <h6 className="h6 timeline">
                  2014 - 2015
                </h6>
                <h4 className="h4">
                Blabla bla
                </h4>
              </li>
              <li className="list-item">
                <h6 className="h6 timeline">
                  2010 - 2014
                </h6>
                <h4 className="h4">
                Blabla blaBlabla bla
                </h4>
              </li>
            </ul>
          </div>
          <div className="previous-work">
            <h2 className="h2">Project</h2>
            <ul className="list">
              <li className="list-item">
                <h6 className="h6 timeline">
                  2021 - New York
                </h6>
                <h4 className="h4">
                  <a href="https://placehold.co/1080x1920.mp4">bal balBla bla</a>
                </h4>
              </li>
              <li className="list-item">
                <h6 className="h6 timeline">
                  2021 - Milan
                </h6>
                <h4 className="h4">
                  <a href="https://placehold.co/1080x1920.mp4">bal balBla bla</a>
                </h4>
              </li>
              <li className="list-item">
                <h6 className="h6 timeline">
                  2020 - Berlin
                </h6>
                <h4 className="h4">
                  <a href="https://placehold.co/1080x1920.mp4">bal balBla bla</a>
                </h4>
              </li>
              <li className="list-item">
                <h6 className="h6 timeline">
                  2018 - Medium
                </h6>
                <h4 className="h4">
                  <a href="https://placehold.co/1080x1920.mp4">bal balBla bla</a>
                </h4>
              </li>
            </ul>
            {/* <ul className="list">
              <li className="list-item">
                <h6 className="h6 timeline">
                  2017 - Design and Engineering
                </h6>
                <h4 className="h4">
                  SteelSeries.com and Engine Software
                </h4>
              </li>
              <li className="list-item">
                <h6 className="h6 timeline">
                  2017 - Design and Engineering
                </h6>
                <h4 className="h4">
                  P.W. LaRue Co. Webshop
                </h4>
              </li>
              <li className="list-item">
                <h6 className="h6 timeline">
                  2015 - Engineering and Design
                </h6>
                <h4 className="h4">
                  Foreign Affairs News
                </h4>
              </li>
              <li className="list-item">
                <h6 className="h6 timeline">
                  2015 - UX
                </h6>
                <h4 className="h4">
                  Yale Art Gallery
                </h4>
              </li>
              <li className="list-item">
                <h4 className="h4">
                  View LinkedIn ->
                </h4>
              </li>
            </ul> */}
          </div>
        </motion.section>

        <motion.section className="container next-project">
          <div className="grid">
            <h2 className="h2 grid-title">Next Project</h2>
          </div>
          <div className="grid work-grid">
            <Card
              image="https://placehold.co/00x00/911000/FFF"
              imageAlt="Project 1"
              link="/project1"
              color="pink"
              title="Project 1"
              size="normal"
              onProjectEnter={projectEnter}
              onProjectLeave={cursorLeave}
            />
            <Card
              image="https://placehold.co/00x00/911000/FFF"
              imageAlt="Project 2"
              link="/project2"
              color="blue"
              title="Project 2"
              size="normal"
              onProjectEnter={projectEnter}
              onProjectLeave={cursorLeave}
            />
          </div>
        </motion.section>
        <Footer onFooterEnter={contactEnter} onFooterLeave={cursorLeave} onLinkEnter={linkEnter} onLinkLeave={cursorLeave}/>

      </div>
  )
}

export default AboutPage
