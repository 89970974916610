import React from 'react';
import { useState } from "react"
import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from './Routes';
import { Helmet } from "react-helmet";

import Navigation from "./components/Navigation"
import './components/global.css';


const App = () => {
  const [cursorText, setCursorText] = useState("");
  const [cursorVariant, setCursorVariant] = useState("default");

  function cursorLeave(event) {
    setCursorText("");
    setCursorVariant("default");
  }

  function linkEnter(event) {
    setCursorText("");
    setCursorVariant("link");
  }

  const duration = 0.3

  const pageVariants = {
    initial: {
      opacity: 1,
      y: 0,
    },
    enter: {
      opacity: 1,
      y: 0,
      transition: {
        duration: duration,
        delay: duration,
        when: "beforeChildren",
      },
    },
    exit: {
      opacity: 0,
      y: -20,
      transition: { duration: duration },
    },
  }

  return (
    <Router>
    <main>
    <Helmet>
        <meta charSet="utf-8" />
        <title>INJURO - Interior Design Studio - Home</title>
        <meta name="description" content="Injuro is an interior design studio based in Milan - Italy" />
        <link rel="canonical" href="https://injuro.xyz/" />
      </Helmet>
      {/* <Navigation onLinkEnter={linkEnter} onLinkLeave={cursorLeave}/> */}
      <AppRoutes />
    </main>
    </Router>
  )
}

export default App;
